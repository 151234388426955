import React from 'react';

import Button from 'react-bootstrap/lib/Button'
import Col from 'react-bootstrap/lib/Col'
import Modal from 'react-bootstrap/lib/Modal'
import Row from 'react-bootstrap/lib/Row'


import apiManager from '../../utils/apiManager';
import { bankEmiApi, emidetailforPinelab, storeConfiguration} from '../../../public/constants/constants';
import {LEARN_MORE,EMI_EASY,NC_EMI_EASY,TOTAL_COST,PLANS,MONTHS,ANNUAL_INTEREST,
  CHARGED_PROVIDER,EQUAL_MONTHLY_INSTALLMENT,PAYABLE_PROVIDER,CONVERT_THE_PAYMENT,NC_EQUAL_MONTHLY_INSTALLMENT,NC_CONVERT_THE_PAYMENT,
  NO_COST_EMI_DISCOUNT,WAVED_OF_BY_GODREJ_INTERIO,
  NC_BANK_WILL_CONTINUE,NC_GODREJ_DOES_NOT_CHARGE} from '../../constants/app/pdpConstants';
import {EMI } from '../../constants/app/cartConstants';

class EmiInfo extends React.Component {
  constructor() {
    super();
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.hideshowNoCostEMIDiv = this.hideshowNoCostEMIDiv.bind(this);
    this.state = {
      show: false,
      loading: true,
      error: false,
      showNoCostEmiList:true,
      NoCostEnabled:false,
      ispinelab:false
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.price !== nextProps.price && (!this.state.ispinelab || !this.props.isfromCart)){
      this.callBankEmiApi(nextProps.price);
    }
  }
	
  componentDidMount() {
    apiManager.get(storeConfiguration).then((response)=>{
      if(response.status === 200){
        this.setState({
          ispinelab: response.data.ispinelab
        })
      }
      (!response.data.ispinelab || !this.props.isfromCart) && this.callBankEmiApi(this.props.price);
    })
  }

  /* Handle Modal Close */
  handleClose() {
    this.setState({ show: false });
  }

  /* Handle Modal Show */
  handleShow() {
    this.setState({ show: true });
  }

  /* call bank emi api */
  callBankEmiApi(price) {
    if(price!='NaN' && price!=0 && price!='0'){
      const reqURL = this.state.ispinelab ? `${emidetailforPinelab}${price}/${this.props.partNumber}` : `${bankEmiApi}${price}`
      apiManager.get(reqURL).then(response => {
      this.setState({
        bankDetails: response.data,
			  	loading: false,
      });
      this.props.setncEMIStarting(response.data.data.ncEMIStarting && response.data.data.emiStarting && response.data.data.ncEMIStarting,response.data.data.emiStarting);
      response.data.data.bankEMIDetails.map((tabData, index) => {
        let showBank='N';
        tabData.emiDetails.map((emiDetails,index1) => {
          {(this.state.ispinelab && emiDetails.ncEMIAvailability=='Y') || (emiDetails.ncEMIAvailability=='Y' && this.props.price>=emiDetails.ncEmiMinSpend
					&& emiDetails.ncEmiMinSpend!=null 
					&& emiDetails.ncEmiMinSpend!='null' 
					&& emiDetails.ncEmiMinSpend!=0)?
            showBank='Y'
            :''
          }

        })
        if(showBank == 'Y'){
          // this.setState({
          // 	NoCostEnabled: true,
          // });
          this.state.NoCostEnabled=true;
        }
      })

    }).catch(error => {
    });
  }
  }
	

  /* display tab with data */
  EmiDetailsTab(divId) {
    const tabcontent = document.getElementsByClassName('bankTabcontent');
    const tabData = document.getElementsByClassName('bankTabData');
    const contentElement = document.getElementById(`bankContent_${divId}`);
    const tabElement = document.getElementById(`bankTab_${divId}`);

    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove('dataNotActive');
      tabcontent[i].classList.add('dataNotActive');
      tabData[i].classList.remove('active');
    }
    contentElement.classList.remove('dataNotActive');
    contentElement.classList.remove('dataActive');
    tabElement.classList.add('active');
  }

  NC_EmiDetailsTab(divId) {
    const tabcontent = document.getElementsByClassName('nc_bankTabcontent');
    const tabData = document.getElementsByClassName('nc_bankTabData');
    const contentElement = document.getElementById(`nc_bankContent_${divId}`);
    const tabElement = document.getElementById(`nc_bankTab_${divId}`);

    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove('dataNotActive');
      tabcontent[i].classList.add('dataNotActive');
      tabData[i].classList.remove('active');
    }
    contentElement.classList.remove('dataNotActive');
    contentElement.classList.remove('dataActive');
    tabElement.classList.add('active');
  }

  /* render tab data */
  renderTabData(data) {
    return (
      data.map((tabData, index) => {
        this.activeClass = 'active';
        if (index > 0) {
          this.activeClass = '';
        }

        return (
          <td
            key={index}
            id={`bankTab_${index}`}
            className={`bankTab bankTabData ${this.activeClass}`}
            onClick={() => this.EmiDetailsTab(index)}
          >
            {tabData.bankName}
          </td>
        );
      })
    );
  }

  renderNoCostEMITabData(data) {
    let activeindex='';
    this.state.NoCostEnabled=false;
    const ncEMIdata = [];
    data.map((tabData, index) => {
      let showBank='N';
      tabData.emiDetails.map((emiDetails,index1) => {
        { (this.state.ispinelab && emiDetails.ncEMIAvailability=='Y') || emiDetails.ncEMIAvailability=='Y' && this.props.price>=emiDetails.ncEmiMinSpend
				&& emiDetails.ncEmiMinSpend!=null 
				&& emiDetails.ncEmiMinSpend!='null' 
				&& emiDetails.ncEmiMinSpend!=0?
          showBank='Y'
          :''
        }
      })
      if(showBank == 'Y'){
        // this.setState({
        // 	NoCostEnabled: true,
        // });
        this.state.NoCostEnabled=true;
        ncEMIdata.push(tabData);
      }
    })
    return (
      ncEMIdata.map((tabData, index) => {
        this.activeClass = 'active';
        if (index > 0) {
          this.activeClass = '';
        }
        let showBank='N';
        tabData.emiDetails.map((emiDetails,index1) => {
          {emiDetails.ncEMIAvailability=='Y' && (this.state.ispinelab || this.props.price>=emiDetails.ncEmiMinSpend)
          // && emiDetails.ncEmiMinSpend!=null 
          // && emiDetails.ncEmiMinSpend!='null' 
          // && emiDetails.ncEmiMinSpend!=0
            ?
            showBank='Y'
            :''
          }
        })
        // if(showBank == 'Y'){
        // 	this.setState({
        // 		NoCostEnabled: true,
        // 	});
        // }
        tabData.emiDetails.map((emiDetails,index1) => {
          {emiDetails.ncEMIAvailability=='Y' && activeindex==''?
            activeindex=index
            :''
          }
        })
        // if (index == activeindex) {
        // 	this.activeClass = '';
        // }
        return (
          showBank == 'Y'?
            <td
              key={index}
              id={`nc_bankTab_${index}`}
              className={`bankTab bankTabData nc_bankTabData ${this.activeClass}`}
              onClick={() => this.NC_EmiDetailsTab(index)}
            >
              {tabData.bankName}
            </td>
            :''
        );
      })
    );
  }

  /* render tab content */
  renderTabContent(data) {
    return (
      data.map((data, index) => {
        this.dataClass = '';

        if (index > 0) {
          this.dataClass = 'dataNotActive';
        }

        let displayContent = '';
        displayContent = data.emiDetails.map((tabContent, id) => ( 
          (!this.state.ispinelab || tabContent.ncEMIAvailability!=='Y') &&				
					 <tr key={id}>						
            <td>{tabContent.tenure}</td>
            <td>{tabContent.emiValue}</td>
            <td>{tabContent.rate}%</td>
            <td>{tabContent.totalAmount}</td>
          </tr>	
        ));

        return (					
          <td
            colSpan={4} key={index}
            id={`bankContent_${index}`}
            className={`bankTabcontent ${this.dataClass}`}
          >						
            <table className="emiPlans table table-striped" width="100%">{displayContent}</table>
          </td>
					
        );
      })
    );
  }

  renderNoCostEMITabContent(data) {
    let activeindex='';
    const ncEMIdata = [];
    data.map((tabData, index) => {
      let showBank='N';
      tabData.emiDetails.map((emiDetails,index1) => {
        {emiDetails.ncEMIAvailability=='Y' && (this.state.ispinelab || (this.props.price>=emiDetails.ncEmiMinSpend
				&& emiDetails.ncEmiMinSpend!=null 
				&& emiDetails.ncEmiMinSpend!='null' 
				&& emiDetails.ncEmiMinSpend!=0))?
          showBank='Y'
          :''
        }
      })
      if(showBank == 'Y'){
        // this.setState({
        // 	NoCostEnabled: true,
        // });
        this.state.NoCostEnabled=true;
        ncEMIdata.push(tabData);
      }
    })
    return (
      ncEMIdata.map((data, index) => {
        this.dataClass = '';

        if (index > 0) {
          this.dataClass = 'dataNotActive';
        }

        let displayContent = '';
        let showBank='N';
        data.emiDetails.map((emiDetails,index1) => {
          {emiDetails.ncEMIAvailability=='Y'?
            showBank='Y'
            :''
          }
        })
        data.emiDetails.map((emiDetails,index1) => {
          {emiDetails.ncEMIAvailability=='Y' && activeindex==''?
            activeindex=index
            :''
          }
        })
        // if (index == activeindex) {
        // 	this.dataClass = '';
        // }
        displayContent = data.emiDetails.map((tabContent, id) => (	
          <>	
            {tabContent.ncEMIAvailability=='Y' && (this.state.ispinelab || this.props.price>=tabContent.ncEmiMinSpend)?
            // showBank='Y'
					
					 <tr key={id}>						
                <td className='td1'>{tabContent.tenure}</td>
                {/* <td>{tabContent.emiValue}</td> */}
                <td className='td2'>{this.state.ispinelab ? tabContent.emiValue : Math.round(this.props.price/tabContent.tenure)}</td>							
                <td className='td3'>{tabContent.rate}%</td>
                {this.state.showNoCostEmiList==true?
                  <td className='td4'>{tabContent.interestAmount}</td>
                  :''}
                <td className='td5'>{Math.round(this.props.price)}</td>
              </tr>	
              :''
            }
          </>
        ));

        return (
          showBank=='Y'?
            <td
              colSpan={5} key={index}
              id={`nc_bankContent_${index}`}
              className={`nc_bankTabcontent ${this.dataClass}`}
            >						
              <table className="emiPlans table table-striped nc-emi-plans" width="100%">{displayContent}</table>
            </td>
            :''
        );
      })
    );
  }

	
  hideshowNoCostEMIDiv(emiType) {

    {emiType=='NC_EMI'?
      this.setState({ showNoCostEmiList: true })
      :
      this.setState({ showNoCostEmiList: false })
    }
		
  }

  render () {
    return (
      <>
        <Button className='btn-knowmore'  onClick={this.handleShow}>{LEARN_MORE}</Button>
        <Modal className='modal_emiInstallment' show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Button className="close" onClick={this.handleClose}></Button>
            <Row>
              <Col xs={12} md={12}>
                <div className='emi_modal'>
                  {this.state.NoCostEnabled==true?
                    <span className='emi-headings'>	
                      <h4 className={this.state.showNoCostEmiList==false?'heading no-cost-emi-heading':'heading no-cost-emi-heading heading-active'} onClick={() => this.hideshowNoCostEMIDiv('NC_EMI')}>{NC_EMI_EASY}</h4>
                      <h4 className={this.state.showNoCostEmiList==false?'heading heading-active':'heading'} onClick={() => this.hideshowNoCostEMIDiv('EMI')}>{EMI_EASY}</h4>
                    </span>
                    :
                    <span className='emi-headings'>	
                      {/* <h4 className={this.state.showNoCostEmiList==false?'heading no-cost-emi-heading':'heading no-cost-emi-heading heading-active'} onClick={() => this.hideshowNoCostEMIDiv('NC_EMI')}>{NC_EMI_EASY}</h4> */}
                      <h4 className="heading heading-active" onClick={() => this.hideshowNoCostEMIDiv('EMI')}>{EMI_EASY}</h4>
                    </span>
                  }
                  {this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?
                    <ul className='emi_list no_cost_emi_list'>
                      <li className='list'>{NC_EQUAL_MONTHLY_INSTALLMENT}</li>
                      <li className='list'>{NC_CONVERT_THE_PAYMENT}</li>
                      <li className='list'>{NC_BANK_WILL_CONTINUE}</li>
                      <li className='list'>{NC_GODREJ_DOES_NOT_CHARGE}</li>
                    </ul>
                    :
									
                    <ul className='emi_list'>
                      <li className='list'>{EQUAL_MONTHLY_INSTALLMENT}</li>
                      <li className='list'>{CONVERT_THE_PAYMENT}</li>
                      {/* <li className='list'>{DEBIT_CARD_PAY}</li> */}
                    </ul>
                  }
									
                  {!this.state.loading && (
                    <>
                      <table width="100%" className="emiPlans table table-striped">
                        <tr className="tabHeading">
                          <td colSpan="4" className={this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?'bankNameList nc-bankNameList':'bankNameList'}>
                            <div className="theadscroll">
                              <table className="tableHeading table" width="100%">
                                <tr>
                                  {this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?
                                    this.renderNoCostEMITabData(this.state.bankDetails.data.bankEMIDetails)
                                    :
                                    this.renderTabData(this.state.bankDetails.data.bankEMIDetails)
                                  }
                                  {/* {this.renderTabData(this.state.bankDetails.data.bankEMIDetails)}		 */}
                                </tr>
                              </table>
                            </div>
                          </td>
											   
                        </tr>
                        <tr className="spacing">
                          <td className="seprater" colSpan="4">&nbsp;</td>
                        </tr>
                      </table>
                      <table width="100%" className="emiPlans table table-striped">
                        <tr className={this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?'bankEmiList nc-bankEmiList':'bankEmiList'}>
                          <td className={this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?'td1':''}>{PLANS}<br/><div className="subText">{MONTHS}</div></td>
                          <td className={this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?'td2':''}>{EMI}<br/><div className="subText">{PAYABLE_PROVIDER}</div></td>
                          <td className={this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?'td3':''}>{ANNUAL_INTEREST}<br/><div className="subText">{CHARGED_PROVIDER}</div></td>
                          {this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?
                            <td className={this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?'td4':''}>{NO_COST_EMI_DISCOUNT}<br/><div className="subText">{WAVED_OF_BY_GODREJ_INTERIO}</div></td>
                            :''}
                          <td className={this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?'td5':''}>{TOTAL_COST}<br/><div className="subText">{PAYABLE_PROVIDER}</div></td>
                        </tr>
											
                        <tr className="banklistdata">
                          {this.state.showNoCostEmiList==true && this.state.NoCostEnabled==true?
                            this.renderNoCostEMITabContent(this.state.bankDetails.data.bankEMIDetails)
                            :
                            this.renderTabContent(this.state.bankDetails.data.bankEMIDetails)
                          }
                          {/* {this.renderTabContent(this.state.bankDetails.data.bankEMIDetails)}										 */}
									        </tr>
                      </table>
                    </>
                  )}
                </div>
              </Col>
            </Row>         
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default EmiInfo;
