import React, { Suspense} from 'react';
import apiManager from '../../utils/apiManager';
import { footerApi } from '../../../public/constants/constants';
import '../../../public/styles/footerContainer/footerContainer.scss';
const Footer = React.lazy(() => import('../../components/Footer/footer'));
const FooterMobile = React.lazy(() => import('./FooterRWD/index'));
import { InView } from 'react-intersection-observer';


class FooterContainer extends React.Component {
  constructor() {
    super();
    this.callFooterApi = this.callFooterApi.bind(this);
    // this.renderChatBot = this.renderChatBot.bind(this);
    this.state = {
      data: {},
      loading: true,
      error: false,
      isMobile: window.innerWidth <= 760,
      inview:false,
    };
  }

  componentDidMount(){
    if(!(window.location.pathname === '/thank-you'||window.location.pathname === '/cart' || window.location.pathname === '/checkout' || window.location.pathname === '/cart/checkout' || window.location.pathname === '/myAccount'
		|| window.location.pathname.includes('/check/payment/') || window.location.pathname.includes('/order/confirm/')))
    {
      this.callFooterApi();
    }
  }

  componentDidUpdate(){
    if(!(this.state.footer && this.state.footer.status==='success')){
      if(!(window.location.pathname === '/thank-you'||window.location.pathname === '/cart' || window.location.pathname === '/checkout' || window.location.pathname === '/cart/checkout' || window.location.pathname === '/myAccount'
		  || window.location.pathname.includes('/check/payment/') || window.location.pathname.includes('/order/confirm/')))
      { 
        this.callFooterApi();
      }
    }
  }
  
  callFooterApi() {
    apiManager
      .get(footerApi)
      .then(response => {
        const { data } = response || {};
        this.setState({
          footer: data && data,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  }

  // renderChatBot() {
  //   const appId = process.env.envKeys.INTERCOM_KEY || 'rf3dsabc';
  //   const intercomSettings = {
  //     app_id: appId,
  //   };
  //   const loginId = appCookie.get('loginID');
  //   if (appCookie.get('isLoggedIn') === 'true') {
  //     if (regexEmail.test(loginId)) {
  //       intercomSettings.email = loginId;
  //     } else if (regexMobileNo.test(loginId)) {
  //       intercomSettings.email = loginId;
  //       intercomSettings.phone = loginId;
  //     }
  //   }
  //   window.Intercom('boot', intercomSettings);
  // }

  render() 
  {
    // this.renderChatBot();
    if(window.location.pathname === '/thank-you'||window.location.pathname === '/cart' || window.location.pathname === '/checkout' || window.location.pathname === '/cart/checkout' || window.location.pathname === '/myAccount'
		|| window.location.pathname.includes('/check/payment/') || window.location.pathname.includes('/order/confirm/'))
    {
      return <></>;
    }		
    if (this.state.isMobile) {
      return (

         <InView as="div" onChange={(inView, entry) => this.setState({inview: this.state.inview == true || inView==true? true: false})} style={{minHeight : '200px'}}>
      {this.state.inview ?
        !this.state.loading && (
            <footer className="footer">
              {!this.state.error &&
                this.state.footer.status === 'success' && (
                <div><Suspense fallback = {<></>}>
                  <FooterMobile
                    links={this.state.footer.data.Footer_Links}
                    newsletter={this.state.footer.data.Footer_Newsletter_Data}
                    socialicons={this.state.footer.data.Footer_Social_Data}
                    stores={this.state.footer.data.Footer_StoreLinks}
                    categories={this.state.footer.data.Footer_Categories}
                  /> </Suspense></ div>
              )}
            </footer>
          ) : <> </>}
           </InView>
      );
    } 

    return (
        <InView as="div" onChange={(inView, entry) => this.setState({inview: this.state.inview == true || inView==true? true: false})} style={{minHeight : '100px'}}>
        { this.state.inview ?
          !this.state.loading && (
          <footer className="footer">
            {!this.state.error &&
              this.state.footer.status === 'success' && (
              <div><Suspense fallback = {<></>}>
                <Footer
                  links={this.state.footer.data.Footer_Links}
                  newsletter={this.state.footer.data.Footer_Newsletter_Data}
                  socialicons={this.state.footer.data.Footer_Social_Data}
                  stores={this.state.footer.data.Footer_StoreLinks}
                  categories={this.state.footer.data.Footer_Categories}
                /></Suspense> </div>
            )}
          </footer>
        ):<></>
        }
        
        {/* <ContentEspot espotName = { 'GI_PIXEL_FOOTER_END' } /> */}
        </InView>
      
    );
  }
}

export default FooterContainer;
