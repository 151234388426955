import React from 'react';
import axios from 'axios';
import {PLEASE_WAIT } from '../../constants/app/checkoutConstants';

import {
  storeId,
  CheckoutAPI,
} from '../../../public/constants/constants';
import appCookie from '../../utils/cookie';
export default class PaymentWait extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      orderId: '',
    };
  }

  componentDidMount() {
    const {orderId} = this.props.match.params;
    const token = appCookie.get('accessToken');
    const data = {
      orderId,
    }
    axios.post(CheckoutAPI, data, {
      headers: { store_id: storeId, access_token: token },
    }).then((res) => {
      if(res.data.data.orderPlaced == true) {
        window.location.assign(`${window.location.origin}/order/confirm/${orderId}`)
              
      } else {
        window.location.assign(`${window.location.origin}/cart/checkout?status=fail`)
      }
    }).catch((err) => {
      window.location.assign(`${window.location.origin}/cart/checkout?status=fail`)
    })
  }
  
  
  render() {
    return (
      <div className='redirectedMsg'>
        <h3>{PLEASE_WAIT}</h3>
      </div>
    );
  }
}
