import React from 'react';
import { minEMIAPI } from '../../../public/constants/constants';
import apiManager from '../../utils/apiManager';
import EMILogo from "../SVGs/emiIcon";
import '../../../public/styles/cart/cartItem.scss';
import { formatPrice,isMobile } from '../../utils/utilityManager';
import {
  STARTING_FROM_RS,
  A_MONTH, 
} from '../../constants/app/cartConstants';
import EmiInfo from '../../components/PdpComponent/emiInfo';


class EMIVal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      emiVal: null,
      isLoading: false,
      error: null,
      ncEMIStarting:'',
      showEmiDiv:false,
    }
  }

  getEmiVal(price) {
    if(price!='NaN' && price!=0 && price!='0'){
    apiManager
      .get(`${minEMIAPI}/${price}`)
      .then(response => {
        this.setState({
          emiVal: response.data.data.minEMIValue,
          isLoading: false,
        });
        this.props.getCartDetails();
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
    }
  }
    
  componentWillReceiveProps(nextProps) {
    if(this.props.price !== nextProps.price){
      this.getEmiVal(nextProps.price);
    }
  }
    
  componentDidMount() {
    this.getEmiVal(this.props.price);
  }
  setncEMIStarting = (value) => {
    this.setState({
      ncEMIStarting:value,
    })
    if(value>0 && this.state.showEmiDiv==false){
      this.setState({
        showEmiDiv:true,
      })
    }
  }
  render() {
    const { emiVal } = this.state;
    return (
      !!emiVal &&
      <div hidden={this.state.showEmiDiv==true?'':'hidden'} id='emiInfoDiv' className={!isMobile() ? 'emiInfo' : 'emiInfo mob-emi-info'}>
            <p className='emiMsg'>
            <span className='emiLogo'>
                <EMILogo width={23} height={23} />
                {/* </span>{STARTING_FROM_RS} {formatPrice(emiVal)} {A_MONTH } */}
                </span>{STARTING_FROM_RS} {Math.round(this.state.ncEMIStarting)} {A_MONTH }                 
                <EmiInfo price={this.props.price} setncEMIStarting={this.setncEMIStarting} isfromCart={true}/>
            </p>
            </div>
    )
  }
}

export default EMIVal;
